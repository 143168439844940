import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = () => {
  return (
    <div className={styles.section}>
      <div className={styles.bannerMobileHome}></div>
      <div className={styles.bannerDeskHome}>
        <div className={styles.lineer}></div>
        <div className="bannerVideo">
          <div>
            <video
              autoPlay
              muted
              loop
              poster="https://invozone-backend.s3.us-east-1.amazonaws.com/home_Page_Banner_1d77028993.webp"
            >
              <source
                src="https://invozone-backend.s3.amazonaws.com/Hero_Section_Loop_Video_Final_Format_b5a1af278b.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <h1>Software Development Consulting For Next-Gen Solutions</h1>
              <p className={styles.mobileHeading}>
                Software Development Consulting For Next-Gen Solutions
              </p>
              <div className={styles.list}>
                <ul>
                  <li>Enterprises</li>
                  <li>Innovators</li>
                  <li>Startups</li>
                </ul>
              </div>
              <div className="mt-4">
                <Link to="/contact-us/">
                  <button
                    className={styles.claim}
                    id="hp_hero_section_start_project_btn_gt1"
                  >
                    Claim Your Free Consultation
                  </button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
export default Banner
