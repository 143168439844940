import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Dropdown from "react-bootstrap/Dropdown"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavLink from "react-bootstrap/NavLink"
import Row from "react-bootstrap/Row"
import caretDown from "../../images/homePage/navDrop.svg"
import arrowIndu from "../../images/navBar2/arrowIndu.svg"
import arrowLeft from "../../images/navBar2/arrowLeft.svg"
import { isValidHttpUrl } from "../../utils/index"
import useWindowDimensions from "../hooks/useWindowDimensions"
import * as styles from "./HeaderHomePage2.module.scss"
import MobileHeader from "./mobileHeader"
import "./navbar.scss"

const navBar = [
  {
    title: "Services We Offer ",
    listType: "grid1",
    link: "software-development-services",
    menus: [
      {
        title: "Our Solutions",
        subMenus: [
          {
            name: "Web Application Development",
            link: "/web-application-development-services/",
          },
          {
            name: "Mobile App Development",
            link: "/mobile-app-development/",
          },
          {
            name: "Enterprise App Development",
            link: "/enterprise-application-development/",
          },
          {
            name: "DevOps Services And Solutions",
            link: "/devops/",
          },
          {
            name: "AI Development",
            link: "/ai-development-services/",
          },
          {
            name: "Quality Assurance & Testing",
            link: "/software-quality-assurance/",
          },
          {
            name: "UI/UX Design",
            link: "/ui-ux-design/",
          },
          {
            name: "iOS Development",
            link: "/mobile-app-development/ios/",
          },
        ],
      },
      {
        title: "Product Engineering",
        subMenus: [
          {
            name: "MVP Development",
            link: "/mvp-development-services/",
          },
          {
            name: "Digital Transformation",
            link: "/contact-us/",
          },
          {
            name: "Product Strategy Consulting",
            link: "/contact-us/",
          },
          {
            name: "No Code Development",
            link: "/no-code-development-services/",
          },
        ],
      },
      {
        title: "Collaborative Models",
        subMenus: [
          {
            name: "Staff/Resource Augmentation",
            link: "/staff-augmentation-company/",
          },
          {
            name: "Dedicated Development Team",
            link: "/dedicated-development-team/",
          },
          {
            name: "Software Development Outsourcing",
            link: "/contact-us/",
          },
        ],
      },
    ],
  },
  {
    title: "Industries We Serve",
    listType: "grid2",
    link: "industries",
    menus: [
      {
        title: "Target Industries",
        subMenus: [
          {
            name: "Fintech",
            project: "Column",
            studies: "A Collaborative Public Info & News Distribution Hub.",
            link: "/fintech/",
            link2: "/portfolio/column/",
          },
          {
            name: "Real Estate",
            studies: "A Fashion & LifeStyle Apparel E-commerce Platform.",
            project: "Shield Republic",
            link: "/contact-us/",
            link2: "/portfolio/shield-republic/",
          },
          {
            project: "GlobalReader",
            studies: "Real-Time Factory Data Tracker For SMEs.",
            name: "e-Commerce",
            link: "/e-commerce/",
            link2: "/portfolio/globalreader/",
          },
          {
            project: "Homepie",
            studies: "Online Marketplace For Real-Estate Buyers & Sellers.",
            name: "On-Demand",
            link: "/on-demand-services/",
            link2: "/portfolio/homepie/",
          },

          {
            name: "Healthcare",
            studies: "HIPAA-Compliant Healthcare Messaging Platform.",
            project: "Stitch",
            link: "/healthcare/",
            link2: "/portfolio/stitch-health/",
          },
          {
            name: "Food & Grocery",
            project: "",
            link: "/food-groceries/",
          },

          {
            name: "Education",
            project: "",
            link: "/education/",
          },
          {
            name: "Travel & Tourism",
            project: "",
            link: "/travel-tourism/",
          },
        ],
      },
    ],
  },
  {
    title: "Portfolio",
    listType: "none",
    link: "portfolio",
  },
  {
    title: "Hire Developers",
    listType: "grid5",
    link: "hire-remote-developers",
    menus: [
      {
        title: "Backend Developers",
        subMenus: [
          {
            name: "Elixir Developer",
            link: "/hire-elixir-developers/",
          },
          {
            name: "ROR Developer",
            link: "/hire-ruby-on-rails-developer/",
          },
          {
            name: "Python Developer",
            link: "/hire-python-developers/",
          },
          {
            name: "Django Developer",
            link: "/hire-django-developers/",
          },
          {
            name: "Node Js Developer",
            link: "/hire-node-js-developer/",
          },
          {
            name: "Golang Developer",
            link: "/hire-golang-developers/",
          },
        ],
      },
      {
        title: "Frontend Developers",
        subMenus: [
          {
            name: "Javascript Developer",
            link: "/javascript-development-company/",
          },
          {
            name: "React Js Developer",
            link: "/hire-react-js-developers/",
          },
          {
            name: "Angular Developer",
            link: "/hire-angular-developers/",
          },
          {
            name: "Typescript Developer",
            link: "/hire-typescript-developers/",
          },
        ],
      },
      {
        title: "Mobile App Developers",
        link: "/hire-mobile-app-developers/",
        subMenus: [
          {
            name: "Flutter Developer",
            link: "/hire-flutter-developers/",
          },
          {
            name: "iOS Developer",
            link: "/hire-ios-developers/",
          },
          {
            name: "Android Developers",
            link: "/hire-android-developers/",
          },
        ],
      },
      {
        title: "Hire By Roles",
        subMenus: [
          {
            name: "Product Manager",
            link: "/hire-product-manager/",
          },
          {
            name: "CTO",
            link: "/hire-cto/",
          },
          {
            name: "SaaS Developer",
            link: "/hire-saas-developers/",
          },
          {
            name: "MERN Stack Developer",
            link: "/hire-mern-stack-developers/",
          },
        ],
      },
    ],
  },
  {
    title: "About Us",
    listType: "grid4",
    link: "about-us",
    menus: [
      {
        subMenus: [
          {
            name: "Podcast Section",
            link: "/podcast/",
          },
          {
            name: "Career Opportunities",
            link: "/careers/",
          },
          {
            name: "Media Hub",
            link: "/events/",
          },
          {
            name: "Explore E-Books",
            link: "/e-books/",
          },
        ],
      },
    ],
  },
  {
    title: "Blog",
    listType: "none",
    link: "blog",
  },
]

const Header = ({ bgChanged = true, ppcPageHeader, isScroll }) => {
  const [navExpanded, setNavExpanded] = useState(false)
  const [renderMenu, setRenderMenu] = useState(false)
  const { width } = useWindowDimensions()

  const [hoveredIndex, setHoveredIndex] = useState(null)

  const data = useStaticQuery(graphql`
    query getMainMenuItems {
      allStrapiMenu {
        nodes {
          menus {
            title
            type
            url
            button {
              title
              url
            }
            sub_menu {
              title
              url
              icon {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { menus: items } = data?.allStrapiMenu?.nodes[0]
  const [transparent, setTransparent] = useState(true)
  const isMobile = width <= 720

  const handleScroll = () => {
    !isMobile && setNavExpanded(false)
    if (window.scrollY < 77) {
      return setTransparent(true)
    } else if (window.scrollY > 77) {
      return setTransparent(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  useEffect(() => {
    setRenderMenu(false)
  }, [])

  const hoverHandler = () => {
    if (isMobile) {
      return setRenderMenu(false)
    }
    setRenderMenu(true)
    // setAnimate(title)
  }

  return (
    <React.Fragment>
      <div className={`${styles.navTop} newBar`}>
        <Navbar
          bg={`${transparent && bgChanged && !navExpanded ? "" : "primary"} `}
          expand="md"
          onToggle={() => setNavExpanded(!navExpanded)}
          expanded={navExpanded}
          style={{ padding: "0 10px 10px 10px" }}
        >
          <div
            className={styles.navBg}
            bg={`${transparent && bgChanged && !navExpanded ? "" : "primary"} `}
          ></div>
          <div className={styles.headerContainer}>
            <Navbar.Brand
              as={Link}
              to={`${ppcPageHeader ? "/" : "/hire-developers/contact-us/"}`}
              className={`w-auto`}
            >
              <StaticImage
                src="../../images/InvoZone_Logo.svg"
                alt="InvoZone"
                placeholder="blurred"
                layout="fixed"
                width={170}
                loading="eager"
                fetchpriority="high"
              />
            </Navbar.Brand>
            {width > 1100 ? (
              <React.Fragment>
                <Navbar.Collapse id="main-navbar-nav">
                  <Nav className="ml-auto">
                    {navBar?.map((item, index) => {
                      const { title, link, listType, menus , button } = item // prettier-ignore
                      if (listType === "none") {
                        return (
                          <React.Fragment key={index}>
                            {isValidHttpUrl(link) ? (
                              <Nav.Link
                                as={Link}
                                href={link}
                                key={`${title}-nv`}
                                id={`header_${link}_btn_gt`}
                              >
                                {title}
                              </Nav.Link>
                            ) : (
                              <Nav.Link
                                as={Link}
                                to={`/${link}/`}
                                key={`${title}-nv`}
                                id={`header_${link}_btn_gt`}
                              >
                                {title}
                              </Nav.Link>
                            )}
                          </React.Fragment>
                        )
                      } else if (listType === "grid1") {
                        return (
                          <Dropdown
                            key={`${title}-ndp`}
                            alignRight
                            className="megaMenuColumnbox"
                            // autoClose="outside"
                          >
                            <Dropdown.Toggle
                              as={NavLink}
                              href={`/${link}/`}
                              disabled={link === "#" ? true : false}
                              className="text-white"
                              id={`dropdown-autoclose-outside-${index}`}
                              onMouseEnter={() => hoverHandler(title)}
                            >
                              {title}
                              <img
                                style={{
                                  filter: "brightness(0) invert(1)",
                                }}
                                src={caretDown}
                                decoding="async"
                                loading="lazy"
                                alt="caret down"
                                className={styles.alignHeaderDropIcon}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu renderOnMount={renderMenu}>
                              <div className={styles.services}>
                                <Container className="align-items-start">
                                  <Row>
                                    {menus &&
                                      menus?.map((e, i) => (
                                        <Col lg={4} key={i}>
                                          <div>
                                            <p className={styles.weOffers}>
                                              {e?.title}
                                            </p>
                                            {e?.subMenus &&
                                              e?.subMenus?.map((el, i) => (
                                                <Link to={el?.link} key={i}>
                                                  <div
                                                    className={`${styles.tabHeading}`}
                                                  >
                                                    <p className={styles.pages}>
                                                      {el?.name}
                                                    </p>
                                                  </div>
                                                </Link>
                                              ))}
                                          </div>
                                        </Col>
                                      ))}
                                  </Row>
                                  <Link to="/software-project-rescue/">
                                    <div className={styles.rescue}>
                                      <div>
                                        <p className={styles.resHead}>
                                          Project Rescue Services
                                        </p>
                                        <p className={styles.resDes}>
                                          We’ve Got Solutions For Optimal
                                          Project Revivals.
                                        </p>
                                      </div>
                                      <div className={styles.arrow}>
                                        <img src={arrowLeft} alt="icon" />
                                      </div>
                                    </div>
                                  </Link>
                                </Container>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      } else if (listType === "grid5") {
                        return (
                          <Dropdown
                            key={`${title}-ndp`}
                            alignRight
                            className="megaMenuColumnbox"
                            // autoClose="outside"
                          >
                            <Dropdown.Toggle
                              as={NavLink}
                              href={`/${link}/`}
                              disabled={link === "#" ? true : false}
                              className="text-white"
                              id={`dropdown-autoclose-outside-${index}`}
                              onMouseEnter={() => hoverHandler(title)}
                            >
                              {title}
                              <img
                                style={{
                                  filter: "brightness(0) invert(1)",
                                }}
                                src={caretDown}
                                decoding="async"
                                loading="lazy"
                                alt="caret down"
                                className={styles.alignHeaderDropIcon}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu renderOnMount={renderMenu}>
                              <div className={styles.services}>
                                <Container className="align-items-start">
                                  <Row>
                                    {menus &&
                                      menus?.map((e, i) => (
                                        <Col
                                          lg={3}
                                          className={styles.hireWidth}
                                          key={i}
                                        >
                                          <div>
                                            <Link to={e?.link}>
                                              <p className={styles.weOffers}>
                                                {e?.title}
                                              </p>
                                            </Link>
                                            {e?.subMenus &&
                                              e?.subMenus?.map((el, i) => (
                                                <Link to={el?.link} key={i}>
                                                  <div
                                                    className={`${styles.tabHeading}`}
                                                  >
                                                    <p className={styles.pages}>
                                                      {el?.name}
                                                    </p>
                                                  </div>
                                                </Link>
                                              ))}
                                          </div>
                                        </Col>
                                      ))}
                                  </Row>
                                  <Link to="/hire-remote-developers/">
                                    <div className={styles.rescue}>
                                      <div>
                                        <p className={styles.resHead}>
                                          Hire Remote Developers
                                        </p>
                                        <p className={styles.resDes}>
                                          InvoZone offers the top talented
                                          remote developers from around the
                                          world.
                                        </p>
                                      </div>
                                      <Link to="/contact-us/">
                                        <div className={styles.arrow}>
                                          <img src={arrowLeft} alt="icon" />
                                        </div>
                                      </Link>
                                    </div>
                                  </Link>
                                </Container>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      } else if (listType === "grid2") {
                        return (
                          <Dropdown
                            key={`${title}-ndp`}
                            alignRight
                            className="megaMenuColumnbox"
                            // autoClose="outside"
                          >
                            <Dropdown.Toggle
                              as={NavLink}
                              href={`/${link}/`}
                              disabled={link === "#" ? true : false}
                              className="text-white"
                              id={`dropdown-autoclose-outside-${index}`}
                              onMouseEnter={() => hoverHandler(title)}
                            >
                              {title}
                              <img
                                style={{
                                  filter: "brightness(0) invert(1)",
                                }}
                                src={caretDown}
                                decoding="async"
                                loading="lazy"
                                alt="caret down"
                                className={styles.alignHeaderDropIcon}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu renderOnMount={renderMenu}>
                              <div className={styles.industriess}>
                                <Container>
                                  <Row className="indusRow">
                                    <Col lg={8}>
                                      {menus &&
                                        menus?.map((e, i) => (
                                          <div key={i}>
                                            <p className={styles.weOffers}>
                                              {e?.title}
                                            </p>
                                            <div className={styles.indHeading}>
                                              {e?.subMenus &&
                                                e?.subMenus?.map((el, i) => (
                                                  <Link to={el?.link} key={i}>
                                                    <div
                                                      className={`${styles.tabHeading}`}
                                                    >
                                                      <p
                                                        className={styles.pages}
                                                      >
                                                        {el?.name}
                                                      </p>
                                                    </div>
                                                  </Link>
                                                ))}
                                            </div>
                                          </div>
                                        ))}
                                      <Dropdown.Divider
                                        className={styles.divider}
                                      />
                                      {menus &&
                                        menus?.map((e, i) => (
                                          <div key={i}>
                                            <p className={styles.weOffers}>
                                              Project Case Studies
                                            </p>

                                            <div className={styles.cases}>
                                              {e?.subMenus &&
                                                e.subMenus.map(
                                                  (el, i) =>
                                                    i < 5 && (
                                                      <div
                                                        key={i}
                                                        onMouseEnter={() =>
                                                          setHoveredIndex(i)
                                                        }
                                                        onMouseLeave={() =>
                                                          setHoveredIndex(null)
                                                        }
                                                      >
                                                        <Link to={el?.link2}>
                                                          <p
                                                            className={
                                                              styles.project
                                                            }
                                                          >
                                                            {el.project}
                                                          </p>
                                                        </Link>
                                                        {hoveredIndex === i && (
                                                          <div>
                                                            <p
                                                              className={
                                                                styles.caseStudies
                                                              }
                                                            >
                                                              {el.studies}
                                                            </p>
                                                            <img
                                                              src={arrowIndu}
                                                              className={
                                                                styles.arrowIndu
                                                              }
                                                              alt="icon"
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                    )
                                                )}
                                            </div>
                                          </div>
                                        ))}
                                    </Col>
                                    <Col lg={4}>
                                      <Link to="/industries/">
                                        <div className={styles.rescue2}>
                                          <div>
                                            <p className={styles.resHead}>
                                              Working In A Different Industry?
                                            </p>
                                            <p className={styles.resDes}>
                                              Our teams can offer solutions
                                              tailored to your needs. Connect
                                              with us to learn more.
                                            </p>
                                          </div>
                                          <div className={styles.arrow}>
                                            <img src={arrowLeft} alt="icon" />
                                          </div>
                                        </div>
                                      </Link>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      } else if (listType === "grid4") {
                        return (
                          <Dropdown
                            key={`${title}-ndp`}
                            alignRight
                            className="megaMenuColumnbox"
                            // autoClose="outside"
                          >
                            <Dropdown.Toggle
                              as={NavLink}
                              href={`/${link}/`}
                              disabled={link === "#" ? true : false}
                              className="text-white"
                              id={`dropdown-autoclose-outside-${index}`}
                              onMouseEnter={() => hoverHandler(title)}
                            >
                              {title}
                              <img
                                style={{
                                  filter: "brightness(0) invert(1)",
                                }}
                                src={caretDown}
                                decoding="async"
                                loading="lazy"
                                alt="caret down"
                                className={styles.alignHeaderDropIcon}
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu renderOnMount={renderMenu}>
                              <div className={styles.company}>
                                <Container>
                                  <Row>
                                    {menus &&
                                      menus?.map((e, i) => (
                                        <div
                                          className={styles.companyData}
                                          key={i}
                                        >
                                          <p className={styles.weOffers}>
                                            Company Profile
                                          </p>
                                          <div className={styles.indHeading}>
                                            {e?.subMenus &&
                                              e?.subMenus?.map((el, i) => (
                                                <Link to={el?.link} key={i}>
                                                  <div
                                                    className={`${styles.tabHeading}`}
                                                  >
                                                    <p className={styles.pages}>
                                                      {el?.name}
                                                    </p>
                                                  </div>
                                                </Link>
                                              ))}
                                          </div>
                                        </div>
                                      ))}
                                  </Row>
                                  <Link to="/about-us/">
                                    <div className={styles.companyCard}>
                                      <div>
                                        <p className={styles.resHead}>
                                          About Us
                                        </p>
                                        <p className={styles.resDes}>
                                          Meet The Team. Explore The Culture
                                        </p>
                                      </div>
                                      <div className={styles.arrow}>
                                        <img src={arrowLeft} alt="icon" />
                                      </div>
                                    </div>
                                  </Link>
                                </Container>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      }
                    })}
                    <Nav.Link
                      as={Link}
                      to="/contact-us/"
                      className={`btn  px-3 ml-3 mt-2 mt-md-0 ${styles.contactLink}`}
                      id="header_contact_us_btn_gt"
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </React.Fragment>
            ) : (
              <MobileHeader items={items} isScroll={isScroll} />
            )}
          </div>
        </Navbar>
      </div>
    </React.Fragment>
  )
}

export default Header
