import { graphql, navigate, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./Industries.module.scss"
import "./Industries.scss"

const Services = () => {
  const strapiData = useStaticQuery(graphql`
    query homeIndustries {
      strapiPage(slug: { eq: "home-page" }) {
        sections {
          title
          cards {
            title
            slug: title2
            description: subTitle
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const { sections: items } = strapiData?.strapiPage

  const [current, setCurrent] = useState(0)

  const settings = {
    dots: true,
    arrows: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    speed: 900,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    dotsClass: "home_button__bar",
    className: "slick-center",
    lazyLoad: "ondemand",
    beforeChange: (currentSlider, next) => setCurrent(next),
    customPaging: i => <h3>{items[0]?.cards[i]?.title}</h3>,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 578,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  function Arrow(props) {
    const { onClick } = props
    return (
      <div
        className={`${styles.iconContainer} ${styles.portfolioArrowRightIconCover}`}
        onClick={onClick}
      >
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_right_444cea6456.svg"
          }
          alt="arrow"
          onClick={onClick}
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          width={25}
          height={25}
        />
      </div>
    )
  }
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div
        className={`${styles.iconContainerLeft} ${styles.portfolioArrowIconCover}`}
        onClick={onClick}
      >
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_left_ff8e28ead0.svg"
          }
          alt="arrow"
          className={styles.portfolioArrowIcon}
          decoding="async"
          loading="lazy"
          width={25}
          height={25}
        />
      </div>
    )
  }
  return (
    <section className={styles.section}>
      <h2 className={`pb-25 main-heading-36 text-center`}>
        Industries in Focus
      </h2>

      <Container>
        <Slider {...settings} className={styles.sliderContainer}>
          {items[0]?.cards?.map((item, i) => {
            return (
              <div className="d-flex justify-content-center" key={i}>
                <div className={styles.industryCard}>
                  <GatsbyImage
                    placeholder="blurred"
                    decoding="async"
                    loading="lazy"
                    image={getImage(item?.image1[0]?.localFile)}
                    alt={item?.slug}
                  />
                  <p className={`${styles.para} text-center text-light pt-3`}>
                    {item?.title}
                  </p>
                  <Button
                    className="btn_white_border"
                    onClick={() => navigate(`/${item?.slug}/`)}
                  >
                    Learn More
                  </Button>
                </div>
              </div>
            )
          })}
        </Slider>
        <Row className="justify-content-center">
          <Col xs={12} md={10} lg={8}>
            <p className="pt-5 text-center pt-5">
              {strapiData && items[0]?.cards[current]?.description}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Services
