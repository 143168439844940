// extracted by mini-css-extract-plugin
export var iconContainer = "Industries-module--iconContainer--c85af";
export var iconContainerLeft = "Industries-module--iconContainerLeft--fc930";
export var industryCard = "Industries-module--industryCard--64707";
export var ondemandText = "Industries-module--ondemandText--c28fc";
export var para = "Industries-module--para--ccdce";
export var portfolioArrowIcon = "Industries-module--portfolioArrowIcon--0960b";
export var portfolioArrowIconCover = "Industries-module--portfolioArrowIconCover--6836a";
export var portfolioArrowRightIconCover = "Industries-module--portfolioArrowRightIconCover--edfcd";
export var section = "Industries-module--section--dd8b5";
export var sliderContainer = "Industries-module--sliderContainer--8cd67";