import { Link } from "gatsby"
import React, { Suspense, useState } from "react"
import * as styles from "./Services.module.scss"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import "./Services.scss"

const Services = ({ isScroll }) => {
  const services = [
    {
      url: "/ai-development-services/",
      serviceName: "AI/ML/CV/ChatGPT",
      description:
        "Automate business operations with our tailor-made AI/ML/ChatGPT solutions to save time, reduce errors and improve efficiency — freeing up your resources to focus on profit-making decisions while enjoying increased accuracy & productivity. ",
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/artifical_intelligence_90f20b3a10_067474d59a.png",
      technologies: [
        {
          techName: "Python",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Python_fa5a8c0173.svg",
          techLink: "/hire-python-developers/",
        },
        {
          techName: "Tensorflow",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/tensorflow_20cb2dcc68.svg",
          techLink: null,
        },
        {
          techName: "Numpy",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/numpy_c461beada5.svg",
          techLink: null,
        },
        {
          techName: "Pytoruch",
          icon: "https://invozone-backend.s3.amazonaws.com/pytorch_98f10a7132.svg",
          techLink: null,
        },
        {
          techName: "ChatGPT",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/chatgpt_0a6ff0d0bc.svg",
          techLink: null,
        },
        {
          techName: "Keras",
          icon: "https://invozone-backend.s3.amazonaws.com/keras_b298a3d4fb.svg",
          techLink: null,
        },
        {
          techName: "OpenCV",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/opencv_4e1882e118.svg",
          techLink: null,
        },
        {
          techName: "SciKit Learn",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scikit_learn_2098e14006_a0e091c669.png",
          techLink: null,
        },
        {
          techName: "Pandas",
          icon: "https://invozone-backend.s3.amazonaws.com/pandas_810c9f6830.svg",
          techLink: null,
        },
        {
          techName: "Scripy",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/scripy_8951adbac3.webp",
          techLink: null,
        },
      ],
    },
    {
      url: "/web-application-development-services/",
      serviceName: "Web Application Development",
      description:
        "Scalable web product development at any stage and ongoing support after launch.",
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/website_13f3a50b68.svg",
      technologies: [
        {
          techName: "Node JS",
          icon: "https://invozone-backend.s3.amazonaws.com/nodejs_f1ad2a2c65.svg",
          techLink: "/hire-node-js-developer/",
        },
        {
          techName: "Laravel",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Laravel_beeb3d1325.svg",
          techLink: null,
        },
        {
          techName: "Python",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Python_fa5a8c0173.svg",
          techLink: "/hire-python-developers/",
        },
        {
          techName: "React JS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_native_dd0e31fd65.svg",
          techLink: null,
        },
        {
          techName: "Angular JS",
          icon: "https://invozone-backend.s3.amazonaws.com/angular_85636a7085.svg",
          techLink: null,
        },
        {
          techName: "Ruby",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Ruby_0f6804a8f2_02850a2352.webp",
          techLink: "/hire-ruby-on-rails-developer/",
        },
        {
          techName: "Elixir",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Elixir_c097615cfc_ab119165c5.webp",
          techLink: "/hire-elixir-developers/",
        },
        {
          techName: "Dot Net",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/microsoft_net_543a4a8355_47ea8d7cbb.png",
          techLink: null,
        },
        {
          techName: "Gatsby",
          icon: "https://invozone-backend.s3.amazonaws.com/gatsby_405ad4bb09.svg",
          techLink: null,
        },
        {
          techName: "GOlang",
          icon: "https://invozone-backend.s3.amazonaws.com/golang_4653fe95d3.svg",
          techLink: "/hire-golang-developers/",
        },
        {
          techName: "HTML5",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/html5_bf38e2c0fb.svg",
          techLink: null,
        },
        {
          techName: "Java",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_54f81a1404.svg",
          techLink: null,
        },
        {
          techName: "JavaScript",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/javascript_6815655da5.svg",
          techLink: null,
        },
        {
          techName: "Rust",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_f907e0df85_d92536094d.webp",
          techLink: null,
        },
        {
          techName: "VueJS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vue_js_15825e0ee8.svg",
          techLink: null,
        },
        {
          techName: "Firebase",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_db5cedd361.svg",
          techLink: null,
        },
        {
          techName: "PostgreSQL",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/postgres_b9aaa14d73_7e3cac3991.webp",
          techLink: null,
        },
        {
          techName: "Bubble.io",
          icon: "https://invozone-backend.s3.amazonaws.com/bubble_io_323be9c22b.svg",
          techLink: null,
        },
        {
          techName: "Zoho",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zoho_f2206d8116.svg",
          techLink: null,
        },
        {
          techName: "Shopify",
          icon: "https://invozone-backend.s3.amazonaws.com/shopify_2634b929db.svg",
          techLink: null,
        },
      ],
    },
    {
      url: "/mobile-app-development/",
      serviceName: "Mobile App Development",
      description:
        "Consultation on updating existing mobile applications and development of apps that look and perform great on both iOS and Android.",
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mobile_9ed69a3cce.svg",
      technologies: [
        {
          techName: "Swift",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Swift_b7f99a9faa.svg",
          techLink: null,
        },
        {
          techName: "Kotlin",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Kotlin_3cc0c4fdc5.svg",
          techLink: null,
        },
        {
          techName: "Objective C",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Objective_C_e3281638b1.svg",
          techLink: null,
        },
        {
          techName: "React Native",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/react_js_58286b2d76.svg",
          techLink: null,
        },
        {
          techName: "Flutter",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/flutter_logo_1_e332f5bea4.svg",
          techLink: "/hire-flutter-developers/",
        },
        {
          techName: "Ionic",
          icon: "https://invozone-backend.s3.amazonaws.com/ionic_8a4a0f560f.svg",
          techLink: null,
        },
        {
          techName: "Android Studio",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Android_Studioa_cd0e18e11c_a3c7b4ce5a.webp",
          techLink: null,
        },
        {
          techName: "Vscode",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vscodea_28a0d43a77.svg",
          techLink: null,
        },
        {
          techName: "Xcode",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/xcode_d913881b42_f136112764.webp",
          techLink: null,
        },
      ],
    },
    {
      url: "/devops/",
      serviceName: "Cloud Services",
      description:
        "Open your digital path using the best DevOps services like Azure, AWS, Kubernetes, Google Cloud, and much more. Taking advantage of our expertise on developing creative cloud solutions, InvoZone provides personalized assistance you need to design a reliable technical infrastructure for your projects.",
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/CYBERSECURITY_a7e2d7c04e_50e526b04c.png",
      technologies: [
        {
          techName: "AWS",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/aws_0f1334e2da.svg",
          techLink: null,
        },
        {
          techName: "Azure",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/azure_26c403c42c.svg",
          techLink: null,
        },
        {
          techName: "Digital Ocean",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_ocean_b77e2516f1.svg",
          techLink: null,
        },
        {
          techName: "Google Cloud",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/google_cloud_211d606a19.svg",
          techLink: null,
        },
        {
          techName: "Jenkins",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jenkins_2ab3319064_3098679743.webp",
          techLink: null,
        },
        {
          techName: "Kubernetes",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kubernetes_f01a38c46d_c07d4beab5.webp",
          techLink: null,
        },
        {
          techName: "Terraform",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/terraform_4b79d902e6.svg",
          techLink: null,
        },
      ],
    },
    {
      url: "https://invogames.com/",
      serviceName: "Game/Metaverse Development",
      description:
        "Developing a conceptual game is a maverick's job, and game developers at InvoZone clearly know the art of creating magical products that match your players’ vision and preferences. Are you ready to launch powerful gameplays?",
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/GAME_945a9b6409.svg",
      technologies: [
        {
          techName: "Unreal Engine",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Unreal_Engine_c852ecdca2.svg",
          techLink: "/hire-unreal-engine-developers/",
        },
        {
          techName: "Meta",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/meta_1_1_414e362315.svg",
          techLink: null,
        },
        {
          techName: "Unity",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Unity_26990ed77e.svg",
          techLink: null,
        },
        {
          techName: "Autodesk 3ds",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Autodesk_3ds_1e26f15ca0.svg",
          techLink: null,
        },
        {
          techName: "Maya",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Maya_ad674fa777.svg",
          techLink: null,
        },
        {
          techName: "Substance",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Substance_d52fb098eb.svg",
          techLink: null,
        },
        {
          techName: "Adobe After Effects",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_after_effects_735b7b835b.svg",
          techLink: null,
        },
        {
          techName: "Adobe Illustrator",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_illustrator_0ea2bd922b.svg",
          techLink: null,
        },
        {
          techName: "Adobe Photoshop",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/adobe_photoshop_1547fdd1f5.svg",
          techLink: null,
        },
        {
          techName: "Blender",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/blender_211db2ae89.svg",
          techLink: null,
        },
        {
          techName: "Roblox",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Roblox_79bed4b3c0_502d56147d.webp",
          techLink: null,
        },
        {
          techName: "Spine",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Spine_Pro_035f3a1efa.svg",
          techLink: null,
        },
        {
          techName: "Vision",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Vision_Pro_84fb720329_39a2eaadbe.webp",
          techLink: null,
        },
        {
          techName: "Chain Safe",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Chain_Safe_6f37e4bb45_aadfd4a453.webp",
          techLink: null,
        },
        {
          techName: "Metamask",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/metamask_1_befdede448_7dd793627f.webp",
          techLink: null,
        },
        {
          techName: "Moralis",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Moralis_Logo_1_5b7720c7a0.svg",
          techLink: null,
        },
        {
          techName: "Nakama",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Nakama_0158e49515.svg",
          techLink: null,
        },
        {
          techName: "Substance Painter",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/substance_painter_2_1_4d21e31b0b.svg",
          techLink: null,
        },
        {
          techName: "Spine Pro",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/spine_pro_8417d6708d.svg",
          techLink: null,
        },
        {
          techName: "MetaHuman",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Meta_Human_389560d5da.svg",
          techLink: null,
        },
      ],
    },
    {
      url: "https://invoblox.com/",
      serviceName: "Blockchain Development",
      description:
        "Become a part of a trustless and borderless distributed network with InvoBlox’s real-world blockchain consultancy and development services. ",
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Blockchain_92c9b044c4.svg",
      technologies: [
        {
          techName: "Ethereum",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Ethereum_e911828052.svg",
          techLink: null,
        },
        {
          techName: "Polygon",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/polygon_04373a698c_88d38415a1.webp",
          techLink: null,
        },
        {
          techName: "Binance",
          icon: "https://invozone-backend.s3.amazonaws.com/binance_e8c42f5ff0.svg",
          techLink: null,
        },
        {
          techName: "Celo",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Celo_8994929348.svg",
          techLink: null,
        },
        {
          techName: "Solana",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Solana_3df015fdb7.svg",
          techLink: null,
        },
        {
          techName: "Algorand",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Algorand_7fe5868978.svg",
          techLink: null,
        },
        {
          techName: "Cardano",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Cardano_5cceb8093c_e4725d28bf.png",
          techLink: null,
        },
        {
          techName: "Substrate",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Substrate_94cbc4a482.webp",
          techLink: null,
        },
        {
          techName: "Polkadot",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Polkadot_220cb68a28.svg",
          techLink: null,
        },
        {
          techName: "Cosmos",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/Cosmos_1b46984110.svg",
          techLink: null,
        },
        {
          techName: "Hyperledger",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/hyperledger_svgrepo_com_2_1_c3677aa903.svg",
          techLink: null,
        },
        {
          techName: "Rust",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rust_f907e0df85_d92536094d.webp",
          techLink: null,
        },
        {
          techName: "Stellar",
          icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/stellar_svgrepo_com_1_d4005c55af.svg",
          techLink: null,
        },
      ],
    },
  ]

  const [focusedService, setFocusedService] = useState(...services)
  const isSSR = typeof window === "undefined"
  const renderTooltip = (name, props) => (
    <Tooltip id="button-tooltip" {...props}>
      {name || "TechStack"}
    </Tooltip>
  )

  const serviceDetail = (
    <>
      <p className={styles.para}>{focusedService?.serviceName}</p>
      <p className={`pb-2 ${styles.subTitle}`}>{focusedService?.description}</p>
      <div
        className={`d-flex align-items-center flex-wrap ${styles.bottomIcon}`}
      >
        {focusedService?.technologies?.map((item, ind) => (
          <div
            key={`${focusedService?.serviceName}_${ind}`}
            className="pb-2 mb-3 pr-4"
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(item?.techName)}
            >
              {item?.techLink ? (
                <Link to={item?.techLink}>
                  <img
                    className="pointerImage"
                    src={item?.icon}
                    height="30"
                    width="30"
                    decoding="async"
                    loading="lazy"
                    alt={item?.techName || "TechStack"}
                  />
                </Link>
              ) : (
                <img
                  className="pointerImage"
                  src={item?.icon}
                  height="30"
                  width="30"
                  decoding="async"
                  loading="lazy"
                  alt={item?.techName || "TechStack"}
                />
              )}
            </OverlayTrigger>
          </div>
        ))}
      </div>
      {!isSSR && (
        <Suspense fallback={<div></div>}>
          {isScroll && (
            <Link
              to={focusedService?.url}
              className={`btn btn_black_border mt-4 mb-5 mb-md-0 ${styles.btnLearn}`}
            >
              Learn More
              <span className={styles.screen_reader_text}>
                {focusedService?.serviceName}
              </span>
            </Link>
          )}
        </Suspense>
      )}
    </>
  )

  return (
    <section>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="text-center mb-3">
              <h2 className={`pb-2 main-heading-36`}>
                Software Consulting & <br />
                Development Services
              </h2>
              <p className="pb-5">
                ...with the perfect blend of technology, expertise and
                innovation.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Row className="justify-content-center">
              {services?.map((item, ind) => (
                <React.Fragment key={`service_${ind}`}>
                  <Col
                    md={6}
                    lg={6}
                    xl={4}
                    className="d-flex justify-content-center"
                  >
                    <div
                      className={`${styles.serviceCard} ${
                        focusedService?.serviceName === item?.serviceName &&
                        styles.focused
                      }`}
                      id={`service_${ind}`}
                      onClick={() => setFocusedService(item)}
                    >
                      <div>
                        <img
                          className={
                            focusedService?.serviceName === item?.serviceName
                              ? "black invertWhite"
                              : "black"
                          }
                          src={item?.icon}
                          alt="Services"
                          // decoding="async"
                          loading="lazy"
                        />
                        <p className={styles.heading}>{item?.serviceName}</p>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    className="d-block d-md-none pl-4 pt-3 centered-row"
                  >
                    {focusedService?.serviceName === item?.serviceName &&
                      serviceDetail}
                  </Col>
                </React.Fragment>
              ))}
            </Row>
          </Col>
          <Col md={6} className={`pl-2 pt-0 pl-md-5 ${styles.serviceDetail}`}>
            {serviceDetail}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Services
