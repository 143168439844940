import React, { Suspense, lazy, useEffect, useState } from "react"
import ContactSales from "../components/common/ContactSales"
import SEORevamp from "../components/common/SEO_Revamp"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Banner from "../components/home-sections/Banner"
import Engagement from "../components/home-sections/Engagement"
import Industries from "../components/home-sections/Industries"
import Services from "../components/home-sections/Services"
import "../components/home-sections/home.scss"
import MainLayout3 from "../layouts/MainLayout3"

const HomePage = () => {
  const [state, setState] = useState(false)
  const VideoSection = state
    ? lazy(() => import("../components/home-sections/VideoSection"))
    : null
  const TwitchExample = state
    ? lazy(() => import("../components/home-sections/stack/VideoTest"))
    : null

  const isSSR = typeof window === "undefined"

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleScroll = () => {
        setState(true)
      }

      window.addEventListener("scroll", handleScroll)
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <MainLayout3>
      <Banner />
      <Services isScroll={state} />
      <Industries />
      <Engagement isScroll={state} />
      {!isSSR && (
        <Suspense fallback={<div>Loading...</div>}>
          {TwitchExample && <TwitchExample />}
        </Suspense>
      )}
      <WorkPortfolio bgColor="#fff" isScroll={state} />
      {!isSSR && (
        <Suspense fallback={<div>Loading...</div>}>
          {VideoSection && <VideoSection />}
        </Suspense>
      )}
      <ContactSales />
    </MainLayout3>
  )
}

export default HomePage

export const Head = () => (
  <SEORevamp
    title="InvoZone | Software Development and Consulting Company"
    description="At InvoZone, we provide software development & consultation services to startups & SMEs. We help tech companies scale their engineering capacity. Contact today!"
    image="https://invozone-backend.s3.amazonaws.com/invozone_og_3df3c5e89a.webp"
  />
)
