// extracted by mini-css-extract-plugin
export var Header2ModuleCreateBoxA7314 = "HeaderHomePage2-module--Header2-module--createBox--a7314--6bd1c";
export var alignHeaderDropIcon = "HeaderHomePage2-module--alignHeaderDropIcon--a6af1";
export var arrow = "HeaderHomePage2-module--arrow--1e492";
export var arrowIndu = "HeaderHomePage2-module--arrowIndu--3dc0b";
export var blink = "HeaderHomePage2-module--blink--f3c14";
export var caseStudies = "HeaderHomePage2-module--caseStudies--13699";
export var cases = "HeaderHomePage2-module--cases--a01d3";
export var company = "HeaderHomePage2-module--company--1e7c9";
export var companyCard = "HeaderHomePage2-module--companyCard--9fad6";
export var companyData = "HeaderHomePage2-module--companyData--a3673";
export var contactLink = "HeaderHomePage2-module--contactLink--8c7b2";
export var headerContainer = "HeaderHomePage2-module--headerContainer--81a70";
export var hireWidth = "HeaderHomePage2-module--hireWidth--7d8f5";
export var indHeading = "HeaderHomePage2-module--indHeading--feef1";
export var industriess = "HeaderHomePage2-module--industriess--b7154";
export var navBg = "HeaderHomePage2-module--navBg--f9fe8";
export var navTop = "HeaderHomePage2-module--navTop--eaa4c";
export var navbar = "HeaderHomePage2-module--navbar--1b83e";
export var pages = "HeaderHomePage2-module--pages--8b8ab";
export var project = "HeaderHomePage2-module--project--95d45";
export var resDes = "HeaderHomePage2-module--resDes--16e47";
export var resHead = "HeaderHomePage2-module--resHead--9ff6f";
export var rescue = "HeaderHomePage2-module--rescue--7125b";
export var rescue2 = "HeaderHomePage2-module--rescue2--31113";
export var services = "HeaderHomePage2-module--services--f2685";
export var tabHeading = "HeaderHomePage2-module--tabHeading--bd808";
export var weOffers = "HeaderHomePage2-module--weOffers--78c39";