// extracted by mini-css-extract-plugin
export var bannerDeskHome = "Banner-module--bannerDeskHome--82952";
export var bannerMobileHome = "Banner-module--bannerMobileHome--c5f1e";
export var buttonPadding = "Banner-module--buttonPadding--e1511";
export var claim = "Banner-module--claim--7e826";
export var content = "Banner-module--content--e7343";
export var lineer = "Banner-module--lineer--ef967";
export var list = "Banner-module--list--87efe";
export var mobileHeading = "Banner-module--mobileHeading--02642";
export var section = "Banner-module--section--e5bdb";
export var videoHeight = "Banner-module--videoHeight--4ba86";