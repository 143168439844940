import { Link } from "gatsby"
import React, { Suspense } from "react"
import illustrationImage from "../../images/home/engagement/Illustration.svg"
import * as styles from "./Engagement.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const Services = ({ isScroll }) => {
  const isSSR = typeof window === "undefined"
  const models = [
    {
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/resource_aug_9a19fb02ac.svg",
      heading: "Staff/Resource Augmentation",
      desc: "Our staff augmentation model is intended to help companies find the right skills for their project.",
    },
    {
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/product_dev_591f8794ae.svg",
      heading: "Product Development",
      desc: "The project-based model is designed to help you get your product built from ideation to deployment by a team of expert software engineering consultants.",
    },
    {
      icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/dedicated_development_team_17e06cc6f5.svg",
      heading: "Dedicated Development Team",
      desc: "Our team of developers is highly qualified and dedicated to delivering quality development services to clients.",
    },
  ]

  return (
    <section>
      <Container>
        <Row>
          <Col md={6}>
            <div className={styles.engagementWrapper}>
              <h2 className="main-heading-36">
                Engagement <span className="text-primary">Models</span>
              </h2>
              <p className="pb-5 pt-3 mb-0">
                We solve your biggest business pains and help you succeed.
                <br /> Here is how we can work together
              </p>
              <div className={styles.illustrationContainer}>
                <img
                  width="100%"
                  height="100%"
                  src={illustrationImage}
                  alt="engagement_model"
                  decoding="async"
                  loading="lazy"
                />
              </div>
            </div>
          </Col>
          <Col md={6}>
            {models?.map((item, ind) => (
              <div
                className={`${styles.engagementCards} ${
                  ind === 1 && "ml-md-5"
                } p-3`}
                key={item.heading}
              >
                {/* Lazy load the images */}
                <img
                  src={item?.icon}
                  alt={item?.heading}
                  decoding="async"
                  loading="lazy"
                  height="55px"
                  width="55px"
                />
                <div className="pl-3">
                  <h3 className="simple-font-weight text-20">
                    {item?.heading}
                  </h3>
                  <p className="mb-0">{item?.desc}</p>
                </div>
              </div>
            ))}
          </Col>
          <Col
            xs={12}
            className={`${styles.btn} justify-content-center d-flex`}
          >
            {!isSSR && (
              <Suspense fallback={<div></div>}>
                {isScroll && (
                  <Link to="/pricing/">
                    <button
                      className={styles.engagmentBtn}
                      id="hp_eng_model_section_gt"
                    >
                      View Pricing
                    </button>
                  </Link>
                )}
              </Suspense>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Services
